import React from 'react';
import './HeroSection.css';

function HeroSection({
  darkMode, mediaFirst, headline, description, Media
}) {
  return (
    <>
      <div className={darkMode ? 'hero-section darkBg' : 'hero-section'}>
        <div className="hero-section__container">
          <div className="row hero-section__row"
          style={{display: 'flex', flexDirection: mediaFirst ? 'row-reverse' : 'row'}}
          >
            <div className="hero-section__col text">
              <div className="hero-section__text-wrapper">
                <h1 className={darkMode ? 'hero-section__heading dark' : 'hero-section__heading'}>{headline}</h1>
                <p className={darkMode ? 'hero-section__description dark' : 'hero-section__description'}>{description}</p>
              </div>
            </div>
            <div className="hero-section__col media">
              <div className="hero-section__media-wrapper">
                <Media className="hero-section__media" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;