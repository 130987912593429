import React from 'react';
import './StartStrip.css';

function StartStrip() {
  return (
    <div className={'home__start-strip'}>
      <h1 className={'home__start-strip-headline'}>The perfect password solution for startups</h1>
      <p className={'home__start-strip-description'}>For startups, by a startup</p>
    </div>
  )
}

export default StartStrip;
