import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Button } from './Button';
import './Navbar.css';
import { IconContext } from 'react-icons/lib';
import { ReactComponent as SquareLogo } from '../logos/logo-square.svg';

function gtag_slack_install_clicked(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-10941360392/zn3pCPOUkM0DEIjSn-Eo',
      'event_callback': callback
  });
  return false;
}

function Navbar() {

  const [NavbarOpen, setNavbarOpen] = useState(false);
  // const [button, setButton] = useState(true);
  const [ProductsOpen, setProductsOpen] = useState(false);

  const handleNavbarOpen = () => setNavbarOpen(!NavbarOpen);
  const closeAll = () => {
    setProductsOpen(false);
    setNavbarOpen(false);
  };

  const onSignupClick = () => {
    setProductsOpen(false);
    setNavbarOpen(false);
    gtag_slack_install_clicked();
  };

  const handleProductsOpen = () => setProductsOpen(!ProductsOpen);
  const closeProducts = () => setProductsOpen(false);

  // const showButton = () => {
  //   if(window.innerWidth <= 960) { // mobile size
  //     setButton(true);
  //   } else { // larger than mobile size
  //     setButton(true);
  //     setClick(false); // change back to default upon 
  //   }
  // }

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener('resize', showButton);

  return (
    <>
      <IconContext.Provider value={{ color: '#0e0e12' }}>
        <div className="navbar">
          <div className="navbar-container">
            <div id="left-menu">
              <Link to='/' className="navbar-logo" onClick={closeAll}>
                <SquareLogo className="navbar-icon" />
                plusidentity
              </Link>
              <ul className={NavbarOpen ? 'nav-menu active' : 'nav-menu'}>
                <li 
                  className={ProductsOpen ? "nav-item nav-products nav-products-expanded" : "nav-item nav-products"}
                  onMouseEnter={
                    NavbarOpen 
                    ? ""
                    : () => handleProductsOpen(!ProductsOpen)
                  }
                  onMouseLeave={
                    NavbarOpen 
                    ? ""
                    : () => closeProducts()
                  }
                  onClick={() => handleProductsOpen(!ProductsOpen)}
                >
                  <span className="nav-products-links">
                    Products
                  </span>
                  <div className="nav-products-space"></div>
                  {ProductsOpen
                    ? <div className="nav-products-dropdown visible">
                        {/* <Link to='/products/basic' className="nav-products-dropdown-item" onClick={closeAll}>
                          Basic
                        </Link>
                        <Link to='/products/plus' className="nav-products-dropdown-item" onClick={closeAll}>
                          Plus
                        </Link> */}
                        <Link to='/products/business' className="nav-products-dropdown-item" onClick={closeAll}>
                          Business
                        </Link>
                        <Link to='/products/advanced' className="nav-products-dropdown-item" onClick={closeAll}>
                          Advanced
                        </Link>
                        {/* <Link to='/products/web' className="nav-products-dropdown-item" onClick={closeAll}>
                          Web
                        </Link> */}
                      </div>
                    : <div className='hidden'></div>
                  }
                </li>
                <li className="nav-item">
                  <Link to='/pricing' className="nav-links" onClick={closeAll}>
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to='/security' className="nav-links" onClick={closeAll}>
                    Security
                  </Link>
                </li>
              </ul>
            </div>
            <div id="right-menu">
              <ul className={NavbarOpen ? 'nav-menu active' : 'nav-menu'}>
                <li className="nav-item">
                  <a href='https://app.plusidentity.com/login' className="nav-links" onClick={closeAll}>
                    Log In
                  </a>
                </li>
                <li className="nav-btn">
                  <a href='https://app.plusidentity.com/signup' className="nav-links" onClick={onSignupClick}>
                    <Button buttonStyle='btn--outline'>SIGN UP</Button>
                  </a>
                </li>
              </ul>
            </div>
            
            <div className="menu-icon" onClick={() => {handleNavbarOpen(); closeProducts()}}>
              {NavbarOpen ? <FaTimes /> : <FaBars />}
            </div>
          </div>
        </div>
      </IconContext.Provider>
    </>
  )
}

export default Navbar
