import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="pricing__title-section">
      <div className="pricing__title-section-headline">
        Pricing for every step of the journey
      </div>
      <div className="pricing__title-section-description">
        Your security companion, from idea to IPO
      </div>
    </div>
  )
}

export default TitleSection;
