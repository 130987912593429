import React from 'react';
import TitleSection from './TitleSection';
import Table from './Table';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



class Pricing extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    // Mixpanel www_pricing_visited
    mixpanel.track('www_pricing_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <>
        <TitleSection />
        <Table 
          mode_info={this.props.mode_info}
        />
      </>
    )
  }
}

export default Pricing;