

import { ReactComponent as ZeroLogo } from './media/svg/security__zero.svg';
import { ReactComponent as EncryptionLogo } from './media/svg/security__encryption.svg';

// import { ReactComponent as StandardsLogo } from './media/svg/security__standards.svg';
import { ReactComponent as SOC2LogoBlack } from './media/svg/security__soc2.svg';



export const Zero = {
  darkMode: false,
  mediaFirst: false,
  headline: 'Zero Knowledge', 
  description: "Encryption and decryption is always handled on the client-side. The PlusIdentity infrastructure is designed such that you don't trust anyone (not even us) - except yourself.",  
  Media: ZeroLogo
}

export const Encryption = {
  darkMode: true,
  mediaFirst: true,
  headline: 'Robust Encryption', 
  description: 'We deploy modern key derivation and encryption algorithms for maximal security.',  
  Media: EncryptionLogo
}

export const SOC2 = {
  darkMode: false,
  mediaFirst: false,
  headline: 'SOC 2 (Type II) Compliant', 
  description: "Reach out to security@plusidentity.com to request our SOC 2 (Type II) report.",  
  Media: SOC2LogoBlack
}