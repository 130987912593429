import React from 'react';

import { ReactComponent as One } from './svg/business__highlight-one.svg';
import { ReactComponent as Two } from './svg/business__highlight-two.svg';
import { ReactComponent as Three } from './svg/business__highlight-three.svg';
import { ReactComponent as Four } from './svg/business__highlight-four.svg';
import { ReactComponent as Five } from './svg/business__highlight-five.svg';

const containerStyle = {
  height: '100%',
  width: '100%',
  margin: '100px 0px 200px 0px',

  display: 'flex',
  flexDirection: 'column',

  justifyContent: 'center',
  alignItems: 'center'

};

const headlineStyle = {
  justifyContent: 'flex-start',
  height: '100%',
  width: '100%',

  margin: '0px 0px 10px 80px',

  fontSize: '30px',
  color: '#707070',
  fontWeight: 100 
}

const oneStyle = {
  position: "static",

  maxWidth: 200,
  maxHeight: 200,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const twoStyle = {
  position: "static",

  // height: "50%",
  maxWidth: 200,
  maxHeight: 200,

  marginLeft: 20,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const threeStyle = {
  position: "static",
  
  maxWidth: 500,
  maxHeight: 200,

  height: '100%',
  width: '100%',

  marginTop: 10,
  marginBottom: 10,

  zIndex: 1
};

const fourStyle = {
  position: "static",
  
  maxWidth: 500,
  maxHeight: 200,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const fiveStyle = {
  position: "static",

  maxHeight: 800,
  maxWidth: 800,

  height: '100%',
  width: '100%',

  zIndex: 0
};


const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  height: '50%',
  width: '100%',

  alignItems: "center",
  justifyContent: 'center',

  margin: '10px 0px 0px 0px',

  maxHeight: 500,
  maxWidth: 1000,
}


const colStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}

const left = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  marginRight: -40,
}

const right = {
  display: 'flex',
  flex: 2
}


function SlackAppMedia(props) {
  return (
    <div className={props.className} style={containerStyle}>
      <span style={headlineStyle}></span>
      <div style={rowStyle}>
        <div style={left}>
          <div style={rowStyle}>
            <One className={'media-item'} style={oneStyle} />
            <Two className={'media-item'} style={twoStyle} />
          </div>
          <div style={colStyle}>
            <Three className={'media-item'} style={threeStyle} />
            <Four className={'media-item'} style={fourStyle} />
          </div>
        </div>
        <div style={right}>
          <Five className={'media-item'} style={fiveStyle} />
        </div>
      </div>
    </div>
  )
}

export default SlackAppMedia;