import React, { useState } from 'react';
import './Table.css';

// Data
import { Plus, Business, Advanced } from './Data';

import Card from './Card';

function Table({ mode_info }) {
  const [billCycle, setBillCycle] = useState('monthly');
  const [user_agreed, set_user_agreed] = useState(false);

  const toggle = (selectedCycle) => {
    if (selectedCycle === "monthly") {
      setBillCycle("monthly");
    } else {
      setBillCycle("yearly");
    }
  };

  const handle_user_agreed = () => {
    set_user_agreed(!user_agreed);
  }


  return (
    <div className="pricing-table">
      <div className="pricing-table__toggle-wrapper">
        <div className="pricing-table__toggle">
          <div className={billCycle === "monthly" ? "pricing-table__toggle-monthly" : "pricing-table__toggle-monthly pricing-table__inactive"} onClick={() => toggle("monthly")}>
            Monthly
          </div>
          {/* <div className={billCycle === "yearly" ? "pricing-table__toggle-yearly" : "pricing-table__toggle-yearly pricing-table__inactive"} onClick={() => toggle("yearly")}> */}
          <div className={billCycle === "yearly" ? "pricing-table__toggle-yearly" : "pricing-table__toggle-yearly pricing-table__inactive"}>
            Yearly
            <span>Yearly plans not yet available</span>
          </div>
        </div>
      </div>
      <div className="pricing-table__table">
        {/* <Card selectedCycle={billCycle} {...Plus} /> */}
        <Card 
          selectedCycle={billCycle}
          {...Business} 
          user_agreed={user_agreed}
          handle_user_agreed={handle_user_agreed}
          mode_info={mode_info}
        />
        <Card 
          selectedCycle={billCycle} 
          {...Advanced}
        />
      </div>
    </div>
  )
}

export default Table;