import React from 'react';

// Data
import { Setup, Free, Companion } from './Data';

// Sections
import HeroSection from '../../HeroSection';
import LandingSection from './LandingSection';
import StartStrip from './StartStrip';
import EndStrip from './EndStrip';


import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN);



class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_agreed: false
    }
  }

  handle_user_agreed = () => {
    this.setState(({
      user_agreed: !this.state.user_agreed
    }))
  }

  componentDidMount = () => {
    // Mixpanel www_home_visited
    mixpanel.track('www_home_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }


  render() {
    return (
      <>
        <LandingSection 
          user_agreed={this.state.user_agreed} 
          handle_user_agreed={this.handle_user_agreed}
          mode_info={this.props.mode_info}
        />
        <StartStrip />
        <HeroSection {...Setup}/>
        <HeroSection {...Free}/>
        <HeroSection {...Companion}/>
        <EndStrip 
          user_agreed={this.state.user_agreed} 
          handle_user_agreed={this.handle_user_agreed}
          mode_info={this.props.mode_info}
        />
      </>
    )
  }
  
}

export default Home;
