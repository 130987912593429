

import { ReactComponent as TechCrunchLogo } from './media/svg/press__techcrunch-logo.svg';
import { ReactComponent as WeeklyDongaLogo } from './media/svg/press__weeklydonga-logo.svg';
import { ReactComponent as ChosunIlboLogo } from './media/svg/press__chosunilbo-logo.svg';
import { ReactComponent as MaeilEconomyLogo } from './media/svg/press__maeileconomy-logo.svg';



export const TechCrunch1 = {
  dateText: "2021.09.01",
  Logo: TechCrunchLogo,
  articleTitle: "Here are all the companies from Y Combinator’s Summer 2021 Demo Day, Part 1",
  articleLink: "https://techcrunch.com/2021/08/31/here-are-all-the-companies-from-y-combinators-summer-2021-demo-day-part-1/"
}

export const WeeklyDonga1 = {
  dateText: "2021.11.04",
  Logo: WeeklyDongaLogo,
  articleTitle: "코로나 시대, 하버드대 두 한국인 청년이 휴학하고 한 일",
  articleLink: "https://weekly.donga.com/Main/3/all/11/3015088/1"
}

export const ChosunIlbo1 = {
  dateText: "2022.02.04",
  Logo: ChosunIlboLogo,
  articleTitle: "[쫌아는기자들] 하버드 유학생 두명, 1분 피칭에 126만달러 투자 받아…무슨 이야기 했을까",
  articleLink: "https://www.chosun.com/economy/smb-venture/2022/02/04/YCUQ73FZTNH63EZQN5LTMTVYXM/"
}

export const MaeilEconomy1 = {
  dateText: "2022.03.27",
  Logo: MaeilEconomyLogo,
  articleTitle: "[#Let's 스타트업] 플러스아이덴티티, 스타트업을 위한 비밀번호 관리 서비스",
  articleLink: "https://www.mk.co.kr/news/it/view/2022/03/276595/"
}


