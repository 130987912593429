import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="pricing__title-section">
      <div className="pricing__title-section-headline">
        Huzzah! Welcome to the party!
      </div>
      <div className="pricing__title-section-description">
        This is the start of something awesome
      </div>
    </div>
  )
}

export default TitleSection;
