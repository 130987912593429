import React from 'react';
import './SecuritySection.css';

function SecuritySection() {
  return (
    <div className="compliance__security-section">
      <div className="compliance__security-section-headline">
        Security Notes
      </div>
      <div className="compliance__security-section-description">
        For detailed notes on our security practices, please refer to our <a href="https://plusidentity.notion.site/Notes-on-PlusIdentity-s-security-8cfda8bf39ce4f909709bbceda5b5bd3" target='_blank'>Security Notes</a>.
      </div>
    </div>
  )
}

export default SecuritySection;
