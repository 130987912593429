import React from 'react';
import './LogoMedia.css';

import { ReactComponent as YCombinator } from './svg/about__ycombinator.svg';
import { ReactComponent as Kleiner } from './svg/about__kleiner.svg';

function LogoMedia() {
  return (
    <div className='about__logo-media'>
      <YCombinator className='about__logo-media-logo' />
      <Kleiner className='about__logo-media-logo' />
    </div>
  )
}

export default LogoMedia;