import React from 'react';

import './EndStrip.css';

import { ReactComponent as AddToSlackBtn } from './media/svg/home__landing-section-add-to-slack.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 



function gtag_slack_install_clicked(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-10941360392/zn3pCPOUkM0DEIjSn-Eo',
      'event_callback': callback
  });
  return false;
}

function gtag_onboarding_clicked(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-10941360392/kWsqCL6_kM0DEIjSn-Eo',
      'event_callback': callback
  });
  return false;
}


class EndStrip extends React.Component {

  constructor(props) {
    super(props);
  }
  
  on_add_to_slack_button_click = () => {
    // Mixpanel www_home_add_to_slack_end_clicked
    mixpanel.track('www_home_add_to_slack_end_clicked', {
      is_dev: this.props.mode_info.is_dev
    });

    // window.gtag('config', 'AW-10941360392');

    gtag_slack_install_clicked();
    // window.gtag('event', 'conversion', {
    //   'send_to': 'AW-10941360392/zn3pCPOUkM0DEIjSn-Eo'
    // });
  }

  on_onboarding_call_link_click = () => {
    // Mixpanel www_home_onboarding_call_end_clicked
    mixpanel.track('www_home_onboarding_call_end_clicked', {
      is_dev: this.props.mode_info.is_dev
    });

    // window.gtag('config', 'AW-10941360392');
    
    gtag_onboarding_clicked();
  }

  render() {
    return (
      <div className='home__end-strip'>
        <h1 className='home__end-strip-headline'>Get secure now. Quick and simple.</h1>
        
        <div>
          <div className='home__end-strip-action-buttons'>
            {this.props.user_agreed
              ? <a 
                  href="https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Ccommands%2Cusers%3Aread%2Cusers%3Aread.email&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhdDp3cml0ZSIsImNvbW1hbmRzIiwidXNlcnM6cmVhZCIsInVzZXJzOnJlYWQuZW1haWwiXX0sIm5vdyI6IjIwMjItMDItMjVUMDQ6MzQ6MTAuMDQ4WiIsImlhdCI6MTY0NTc2MzY1MH0.0xLH5NO_boEmzTWynYHSTQPF9j_adOC-rYb2iyWvMQ8&client_id=1513829906626.2825345191142" 
                  target="_blank"
                  onClick={this.on_add_to_slack_button_click}
                >
                  <AddToSlackBtn className="home__end-strip-button" />
                </a>
              : <a>
                  <AddToSlackBtn className="home__end-strip-button disabled" />
                </a>
            }
            <div className="home__end-strip-onboarding-call-link">
              <a 
                href="https://calendly.com/channy-plusidentity/plusidentity-product-onboarding" 
                target="_blank"
                onClick={this.on_onboarding_call_link_click}
              >
                or set up an onboarding call
              </a>
            </div>
          </div>
  
          <div
            className='home__end-strip-agreed'
            onClick={this.props.handle_user_agreed}
          >
            <input
              type='checkbox'
              checked={this.props.user_agreed}
              value={this.props.user_agreed}
              onChange={this.props.handle_user_agreed}
              className='home__end-strip-agreed-checkbox'
            />
            <span>I am installing as a Primary Owner / Workspace Admin user on my Slack workspace, and I agree to PlusIdentity's <a href='https://www.plusidentity.com/terms' target='_blank'>Terms of Service</a> and <a href='https://www.plusidentity.com/privacy' target='_blank'>Privacy Policy</a>.</span>
          </div>
        </div>
        
      </div>
    )
  }
  
}

export default EndStrip;