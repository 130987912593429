import React from 'react';
import './MissionStrip.css';

function MissionStrip() {
  return (
    <div className={'about__mission-strip'}>
      <h1 className={'about__mission-strip__headline'}>Our mission</h1>
      <p className={'about__mission-strip__description'}>We’re on our way to become the security & identity backbone of every business.</p>
    </div>
  )
}

export default MissionStrip;
