import React from 'react';
import { Link } from 'react-router-dom';

// import { ReactComponent as Basic } from './svg/home__free-section-basic.svg';
// import { ReactComponent as Plus } from './svg/home__free-section-plus.svg';
import { ReactComponent as Business } from './svg/home__free-section-business.svg';
import { ReactComponent as Advanced } from './svg/home__free-section-advanced.svg';
import { ReactComponent as PricingButton } from './svg/home__free-section-pricing-button.svg';

const containerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
};

const leftStyle = {
  flex: 1,

  display: 'flex',
  flexDirection: 'column'
};

const rightStyle = {
  flex: 5,

  display: 'flex',
  flexDirection: 'column'
};

const topStyle = {
  flex: 5,

  marginBottom: 5,

  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
};

const bottomStyle = {
  flex: 1,

  marginTop: 5,

  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
};

const BlockStyle = {
  height: '100%',
  width: '100%',

  marginRight: 5,
  marginLeft: 5
}

const PricingButtonStyle = {
  height: '100%',
  width: '98%',

  marginRight: 5,
  marginLeft: 5
}

const bottomText = {
  fontSize: 14,

  marginRight: 15,
  width: '100%',
  fontStyle: 'italic',
  textAlign: 'center',

  alignSelf: 'flex-end',
  paddingBottom: '18%'
}

const centerText = {
  fontSize: 14,

  marginRight: 10,
  width: '100%',
  fontStyle: 'italic',
  textAlign: 'center',
  alignSelf: 'center'
}


function FreeMedia(props) {
  return (
    <div className={props.className} style={containerStyle}>
      <div style={leftStyle}>
        <div style={topStyle}>
          <div style={bottomText}>
            Less than 5
          </div>
        </div>
        <div style={bottomStyle}>
          <div style={centerText}>
            5+
          </div>
        </div>
      </div>
      <div style={rightStyle}>
        <div style={topStyle}>
          <Business className={'media-item'} style={BlockStyle} />
          <Advanced className={'media-item'} style={BlockStyle} />
        </div>
        <div style={bottomStyle}>
          <Link to='/pricing'>
            <PricingButton className={'media-item clickable'} style={PricingButtonStyle} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default FreeMedia;