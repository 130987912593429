import React from 'react';

import './CompanionMedia.css';


const containerStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',

  borderRadius: 100,
  background: 'radial-gradient(#848484 10px, #aaa 30%, #fff 70%)',

  alignItems: 'center',
};


const row = {
  display: 'flex',
  flexDirection: 'row'
}






function CompanionMedia(props) {
  return (
    <div className={props.className} style={containerStyle}>
      <div style={row}>
        <div className={'companion-media__bubble top-left media-item'} >
          Onboarding new employees
        </div>
        <div className={'companion-media__bubble top-right media-item'} >
          SOC 2
        </div>
      </div>
      <div style={row}>
        <div className={'companion-media__bubble bottom-left media-item'} >
          ISO 27001
        </div>
        <div className={'companion-media__bubble bottom-right media-item'} >
          Employee offboarding
        </div>
      </div>
    </div>
  )
}

export default CompanionMedia;