import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="press__title-section">
      <div className="press__title-section-headline">
        Press Coverage
      </div>
    </div>
  )
}

export default TitleSection;