
// Media section for individual heroes
import SetupMedia from './media/SetupMedia';
import FreeMedia from './media/FreeMedia';
import CompanionMedia from './media/CompanionMedia';



export const Setup = {
  darkMode: false,
  mediaFirst: true,
  headline: 'The simplest setup', 
  description: 'Install on Slack workspace. Set up master password. Start using. Yup, it\'s that simple.',  
  Media: SetupMedia
}

export const Free = {
  darkMode: false,
  mediaFirst: false,
  headline: 'Free for teams of less than 5', 
  description: 'Don’t waste time putting down your credit card number. Just start using. (We’ll ask for money when the time comes)',  
  Media: FreeMedia
}

export const Companion = {
  darkMode: false,
  mediaFirst: true,
  headline: 'Your security & identity companion', 
  description: 'We know your needs will grow as you grow. As you scale, hire & fire, as you go through security compliance — we’ll be there from A to Z, idea to IPO.',  
  Media: CompanionMedia
}