import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="articles__title-section">
      <div className="articles__title-section-headline">
        Blog Articles & Updates
      </div>
    </div>
  )
}

export default TitleSection;