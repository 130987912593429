


// Action Buttons
import { ReactComponent as AddToSlackBtn } from './media/svg/pricing__card-add-to-slack.svg';
import WaitlistButton from './media/WaitlistButton';

// Icons
import { ReactComponent as BasicIcon } from './media/svg/pricing__card-basic-icon.svg';
import { ReactComponent as PlusIcon } from './media/svg/pricing__card-plus-icon.svg';
function AdvancedIcon() {
  const style = {
    fontStyle: 'italic',
    fontSize: 12,
    width: '80%',
    textAlign: 'center'
  }

  return (
    <span style={style}>Integrated across your favorite tools for work</span>
  )
}


// NOT USED RIGHT NOW
export const Plus = {
  title: 'P L U S',
  Icon: BasicIcon,
  monthlyLessThanTwentyFivePricing: '0', 
  monthlyPricing: 'Free', // $0
  yearlyLessThanTwentyFivePricing: '0',
  yearlyPricing: '2.39',
  yearlyBillAmount: '28.68',
  actionButtonLink: "https://slack.com/oauth/v2/authorize?scope=channels%3Amanage%2Cchat%3Awrite%2Ccommands%2Cim%3Ahistory%2Cgroups%3Awrite%2Cim%3Awrite%2Cmpim%3Awrite%2Cusers%3Aread%2Cusers%3Aread.email&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhbm5lbHM6bWFuYWdlIiwiY2hhdDp3cml0ZSIsImNvbW1hbmRzIiwiaW06aGlzdG9yeSIsImdyb3Vwczp3cml0ZSIsImltOndyaXRlIiwibXBpbTp3cml0ZSIsInVzZXJzOnJlYWQiLCJ1c2VyczpyZWFkLmVtYWlsIl19LCJub3ciOiIyMDIxLTA4LTI2VDAzOjI5OjEyLjI5NFoiLCJpYXQiOjE2Mjk5NDg1NTJ9.at_O_AyJBXo0aqP9upfjTtx-ptHmM8uuGXRttNuH7no&client_id=1513829906626.2300993999987", // THIS IS slack-deprecated
  ActionButton: AddToSlackBtn,
  featureListIntro: "", 
  features: [
    'An awesome Slack app',
    'Granular access provisioning',
    'One-time access feature'
  ],
  learnMoreLink: '/products/basic',
  isPlus: true
}

export const Business = {
  title: 'B U S I N E S S',
  Icon: PlusIcon,
  monthlyLessThanTwentyFivePricing: 'Free', // $0
  monthlyPricing: '$8.00 / user / month',  
  yearlyLessThanTwentyFivePricing: '0',
  yearlyPricing: '7.99',
  yearlyBillAmount: '95.88',
  actionButtonLink: "https://slack.com/oauth/v2/authorize?scope=chat%3Awrite%2Ccommands%2Cusers%3Aread%2Cusers%3Aread.email&state=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpbnN0YWxsT3B0aW9ucyI6eyJzY29wZXMiOlsiY2hhdDp3cml0ZSIsImNvbW1hbmRzIiwidXNlcnM6cmVhZCIsInVzZXJzOnJlYWQuZW1haWwiXX0sIm5vdyI6IjIwMjItMDItMjVUMDQ6MzQ6MTAuMDQ4WiIsImlhdCI6MTY0NTc2MzY1MH0.0xLH5NO_boEmzTWynYHSTQPF9j_adOC-rYb2iyWvMQ8&client_id=1513829906626.2825345191142",
  ActionButton: AddToSlackBtn,
  featureListIntro: "", 
  features: [
    'An awesome Slack + web app password manager',
    'Password manager integrated with your favorite startup tools',
    'Onboarding / offboarding solution',
    'Robust solution for security compliance requirements'
  ],
  learnMoreLink: '/products/business',
  isBusiness: true
}

export const Advanced = {
  title: 'A D V A N C E D',
  Icon: AdvancedIcon,
  monthlyLessThanTwentyFivePricing: '0', 
  monthlyPricing: '$25.00 / user / month',  
  yearlyLessThanTwentyFivePricing: '0',
  yearlyPricing: '19.99',
  yearlyBillAmount: '239.88',
  actionButtonLink: "https://www.plusidentity.com/advanced-waitlist",
  ActionButton: WaitlistButton,
  featureListIntro: "All the features of Business, and:", 
  features: [
    'Comprehensive IAM',
    'Deep SSO / IAM integrations across favorite startup tools',
    'Automated workflows',
    'Secure storage and data governance'
  ],
  learnMoreLink: '/products/advanced',
  isBusiness: false
}