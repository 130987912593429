import React from 'react';

// Data
import { Investors } from './Data';


// Sections
import InvestorsSection from './InvestorsSection';
import TitleSection from './TitleSection';
import MissionStrip from './MissionStrip';

import People from './People';



// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class About extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount = () => {
    // Mixpanel www_about_visited
    mixpanel.track('www_about_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <>
        <TitleSection />
        <MissionStrip />
        <InvestorsSection {...Investors} />
        <People />
      </>
    )
  }
}

export default About
