import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import { ReactComponent as SquareLogo } from '../logos/logo-square.svg';
import { ReactComponent as SOC2Logo } from '../logos/soc2-blue.svg';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 


class Footer extends React.Component {

  constructor(props) {
    super(props);
  }
  
  on_careers_link_click = () => {
    // Mixpanel www_careers_visited
    mixpanel.track('www_careers_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <div className="footer">
        <div className="footer__top-row">
          <div className="footer__left">
            <div className="footer__square-logo">
              <SquareLogo className="footer__square-icon" />
              plusidentity
            </div>
            <a href="mailto:support@plusidentity.com" target="_blank">support@plusidentity.com</a>
            <SOC2Logo className="footer__soc2-logo" />
          </div>
          <div className="footer__right">
            <div className="footer__right-col">
              <span className="footer__right-col-title">Products</span>
              <ul className="footer__right-col-list">
                {/* <li><Link to="/products/basic">Basic</Link></li>
                <li><Link to="/products/plus">Plus</Link></li> */}
                <li><Link to="/products/business">Business</Link></li>
                <li><Link to="/products/advanced">Advanced</Link></li>
                {/* <li><Link to="/products/web">Web Dashboard</Link></li> */}
              </ul>
            </div>
            <div className="footer__right-col">
              <span className="footer__right-col-title">Resources</span>
              <ul className="footer__right-col-list">
                <li><Link to="/pricing">Pricing</Link></li>
                <li><Link to="/security">Security</Link></li>
                <li><Link to="/compliance">Compliance</Link></li>
                <li><Link to="/articles">Articles</Link></li>
              </ul>
            </div>
            <div className="footer__right-col">
              <span className="footer__right-col-title">Company</span>
              <ul className="footer__right-col-list">
                <li><Link to="/about">About</Link></li>
                <li><a href="https://www.plusidentity.com/careers" onClick={this.on_careers_link_click}>Careers</a></li>
                <li><Link to="/press">Press</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__bottom-row">
          <div className="footer__copyright">Copyright © 2022 PlusIdentity, Inc. All rights reserved.</div>
          <span className="footer__dot"></span>
          <Link to="/privacy">Privacy</Link>
          <span className="footer__dot"></span>
          <Link to="/terms">Terms</Link>
        </div>
      </div>
    )
  }
}

export default Footer;
