import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="security__title-section">
      <div className="security__title-section-headline">
        The security of your data is our utmost priority
      </div>
      <div className="security__title-section-description">
        We equate it to the very existence of PlusIdentity
      </div>
    </div>
  )
}

export default TitleSection;