import React from 'react';
import './TitleSection.css';


function TitleSection() {
  return (
    <div className="advanced__title-section">
      <span className="advanced__title-section-tier">
        A D V A N C E D
      </span>
      <div className="advanced__title-section-headline">
        {/* <AdvancedIcon className="advanced__title-section-headline-icon" /> */}
        {/* <span className="advanced__title-section-headline-text">Browser extension</span> */}
      </div>
      <div className="advanced__title-section-description">
        Integrated across your favorite tools for work
      </div>
      <span className="advanced__title-section-status advanced__title-in-development">
        I N &nbsp;&nbsp;&nbsp; D E V E L O P M E N T
      </span>
    </div>
  )
}

export default TitleSection;