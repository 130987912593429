import React from 'react';
import './PressList.css';

import { TechCrunch1, WeeklyDonga1, ChosunIlbo1, MaeilEconomy1 } from './Data';

import PressItem from './PressItem';

function PressList() {
  return (
    <div className="press__press-list">
      <PressItem {...MaeilEconomy1} />
      <PressItem {...ChosunIlbo1} />
      <PressItem {...WeeklyDonga1} />
      <PressItem {...TechCrunch1} />
    </div>
  )
}

export default PressList
