import React from 'react';
import './WaitlistButton.css';

function WaitlistButton() {
  return (
    <div className="pricing__waitlist-button">
      <span>Join the waitlist</span>
    </div>
  )
}

export default WaitlistButton
