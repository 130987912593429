import React from 'react';
import './Person.css';

function Person({
  Pic, fullName, firstLine, secondLine
}) {
  return (
    <div className='about__person'>
      <Pic className='about__person-pic' />
      <span className='about__person-full-name'>{fullName}</span>
      <span className='about__person-first-line'>{firstLine}</span>
      <span className='about__person-second-line'>{secondLine}</span>
    </div>
  )
}

export default Person;
