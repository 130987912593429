import React from 'react';
import './PlusMedia.css';


import { ReactComponent as Popup } from './svg/plus__highlight-section-media-popup.svg';
import { ReactComponent as Autologin } from './svg/plus__highlight-section-media-autologin.svg';


function PlusMedia() {
  return (
    <div className="plus__highlight-section-media">
      <Popup className="plus__highlight-section-media-popup media-item"/>
      <Autologin className="plus__highlight-section-media-autologin media-item"/>
    </div>
  )
}

export default PlusMedia
