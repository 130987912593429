import React from 'react';
import './ArticlesItem.css';


function ArticlesItem({
  dateText, Logo, articleTitle, articleLink
}) {
  return (
    <a className="articles__articles-item" href={articleLink} target="_blank">
      <span className="articles__articles-item-date">{dateText}</span>
      <Logo className="articles__articles-item-logo" />
      <span className="articles__articles-item-article-title">{articleTitle}</span>
    </a>
  )
}

export default ArticlesItem;
