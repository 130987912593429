
// Media section for individual heroes
import LogoMedia from './media/LogoMedia';


// People profile pics
import { ReactComponent as ChannyProfile } from './media/svg/about__pic-channy.svg';
import { ReactComponent as IkeProfile } from './media/svg/about__pic-ike.svg';
import { ReactComponent as SandorProfile } from './media/svg/about__pic-sandor.svg';





export const Investors = {
  darkMode: false,
  mediaFirst: true,
  headline: 'Our investors', 
  description: 'We are fortunate enough to be backed by the legends of Silicon Valley like Y Combinator and Kleiner Perkins.',  
  Media: LogoMedia
}

export const Channy = {
  Pic: ChannyProfile,
  fullName: 'Channy Hong',
  firstLine: 'Computer Science', 
  secondLine: 'Harvard'
}

export const Ike = {
  Pic: IkeProfile,
  fullName: 'Ike Jin Park',
  firstLine: 'Econ & Phil', 
  secondLine: 'Harvard'
}

export const Sandor = {
  Pic: SandorProfile,
  fullName: 'Sandor Palfy',
  firstLine: 'Former CTO', 
  secondLine: 'LastPass'
}
