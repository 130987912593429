import React from 'react';
import './ArticlesList.css';

import { noneYet, zeroKnowledge, soc2 } from './Data';

import PressItem from './ArticlesItem';

function ArticlesList() {
  return (
    <div className="articles__articles-list">
      <PressItem {...soc2} />
      <PressItem {...zeroKnowledge} />
    </div>
  )
}

export default ArticlesList
