import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="about__title-section">
      <div className="about__title-section-headline">
        The most startup friendly password manager
      </div>
      <div className="about__title-section-description">
        For startups, by a startup
      </div>
    </div>
  )
}

export default TitleSection;