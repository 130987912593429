import React from 'react';
import './PressItem.css';


function PressItem({
  dateText, Logo, articleTitle, articleLink
}) {
  return (
    <a className="press__press-item" href={articleLink} target="_blank">
      <span className="press__press-item-date">{dateText}</span>
      <Logo className="press__press-item-logo" />
      <span className="press__press-item-article-title">{articleTitle}</span>
    </a>
  )
}

export default PressItem;
