import React from 'react';
import './TitleSection.css';

import { ReactComponent as PlusIcon } from './media/svg/plus__plus-icon.svg';


function TitleSection() {
  return (
    <div className="business__title-section">
      <span className="business__title-section-tier">
        B U S I N E S S
      </span>
      <div className="business__title-section-headline">
        <PlusIcon className="business__title-section-headline-icon" />
        <span className="business__title-section-headline-text">Slack + Web App</span>
      </div>
      <div className="business__title-section-description">
        Simply the best password manager for startups
      </div>
      <span className="business__title-section-status business__title-live">
        L I V E
      </span>
    </div>
  )
}

export default TitleSection;