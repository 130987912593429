import React from 'react';
import './HighlightSection.css';

import { ReactComponent as TickGray } from './media/svg/plus__tick-gray.svg';

import PlusMedia from './media/PlusMedia';

const features = [
  "An awesome Slack + web app password manager",
  "Auto-fill and auto-login features",
  "Password manager integrated across your favorite startup tools",
  "Employee onboarding / offboarding",
  "Robust solution for SOC2 compliance and beyond"
];

function HighlightSection() {
  return (
    <div className="plus__highlight-section">
      <div className="plus__highlight-section-features">
        <span className="plus__highlight-section-features-title">F E A T U R E S</span>
        <ul className="plus__highlight-section-features-list">
          {features.map((feature) => (
            <li key={feature} className="plus__highlight-section-feature">
              <TickGray className="plus__highlight-section-feature-tick"/>
              <span className="plus__highlight-section-feature-text">{feature}</span>
            </li>
          ))} 
        </ul>
      </div>
      <PlusMedia />
    </div>
  )
}

export default HighlightSection;
