import React from 'react';
import './Advisors.css';


import Person from './Person';

import { Sandor } from './Data';

function Advisors() {
  return (
    <div className='about__advisors'>
      <span className='about__advisors-title'>Our advisor</span> {/* <span>Our advisors</span> */}
      <div className="about__advisors-members">
        <Person {...Sandor} />
      </div>
    </div>
  )
}

export default Advisors;
