import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as TickBlack } from './media/svg/pricing__card-tick-black.svg';
import { ReactComponent as TickWhite } from './media/svg/pricing__card-tick-white.svg';


import './Card.css'

// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




function Card({
  selectedCycle, title, Icon, monthlyLessThanTwentyFivePricing, monthlyPricing, yearlyLessThanTwentyFivePricing, yearlyPricing, yearlyBillAmount, ActionButton, actionButtonLink, featureListIntro, features, learnMoreLink, isBusiness, user_agreed, handle_user_agreed
}) {
  return (
    <div className={selectedCycle == "monthly" ? "pricing__card" : "pricing__card yearly"}>
      <div className="pricing__card-title">{title}</div>
      <Icon className="pricing__card-icon" />
      {
        isBusiness
        ? <>
            <div className="pricing__card-less-than-five-pricing">
              <span className="pricing__card-small-text">Teams of less than 5:</span>
              <span className="pricing__card-normal-text">{selectedCycle == "monthly" ? monthlyLessThanTwentyFivePricing : yearlyLessThanTwentyFivePricing}</span>
            </div>
            <div className="pricing__card-pricing">
              <span className="pricing__card-small-text">Teams of 5+:</span>
              <span className="pricing__card-normal-text">{selectedCycle == "monthly" ? monthlyPricing : yearlyPricing}</span>
            </div>
          </>
        : <>
            <span className="pricing__card-normal-text pricing__single-number">{selectedCycle == "monthly" ? monthlyPricing : yearlyPricing}</span>
          </>
      }
      
      <div className="pricing__card-yearly-bill-amount">
        <span>{selectedCycle == "yearly" ? '* billed yearly' : ''}</span>
      </div>
      

      {
        isBusiness
        ? <>
            {
              user_agreed
              ? <a 
                  className="pricing__card-action-button"
                  href={actionButtonLink} 
                  target="_blank"
                  onClick={() => {
                    // Mixpanel www_pricing_add_to_slack_clicked
                    mixpanel.track('www_pricing_add_to_slack_clicked', {
                      is_dev: this.props.mode_info.is_dev
                    });
                  }}
                >
                  <ActionButton />
                </a>
              : <a className="pricing__card-action-button disabled">
                  <ActionButton  />
                </a>
            }
            <div
              className='pricing__card-agreed'
              onClick={handle_user_agreed}
            >
              <input
                type='checkbox'
                checked={user_agreed}
                value={user_agreed}
                onChange={handle_user_agreed}
                className='pricing__card-agreed-checkbox'
              />
              <span>I am installing as a Primary Owner / Workspace Admin user on my Slack workspace, and I agree to PlusIdentity's <a href='https://www.plusidentity.com/terms' target='_blank'>Terms of Service</a> and <a href='https://www.plusidentity.com/privacy' target='_blank'>Privacy Policy</a>.</span>
            </div>
          </>
        : <a 
            className="pricing__card-action-button" 
            href={actionButtonLink} 
            target="_blank"
          >
            <ActionButton />
          </a>
      }

      <ul className="pricing__card-feature-list">
        <span className="pricing__card-small-text">{featureListIntro}</span>
        {features.map((feature) => (
          <li key={feature} className="pricing__card-feature">
            <TickWhite className="pricing__card-feature-checkmark" />
            <span className="pricing__card-feature-text">{feature}</span>
          </li>
        ))}
      </ul>
      <div className="pricing__card-learn-more"><Link to={learnMoreLink}>Learn more</Link></div>
    </div>
  )
}

export default Card;
