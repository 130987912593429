import React from 'react';
import './TitleSection.css';

function TitleSection() {
  return (
    <div className="compliance__title-section">
      <div className="compliance__title-section-headline">
        Compliance
      </div>
      <div className="compliance__title-section-description">
        We're on our way to become SOC 2 Type II compliant
      </div>
    </div>
  )
}

export default TitleSection;
