import React from 'react';

import './EndStrip.css';

function EndStrip() {
  return (
    <div className='security__end-strip'>
      <h1 className='security__end-strip-headline'>More on security</h1>
      <div className='security__end-strip-description'>
        For more information on our security practices, please refer to our <a href="https://plusidentity.notion.site/Notes-on-PlusIdentity-s-security-8cfda8bf39ce4f909709bbceda5b5bd3" target='_blank'>Security Notes</a>.
      </div>
      <div className='security__end-strip-description'>
        Or if you would like to report an issue, please email us at <a href="mailto:security@plusidentity.com">security@plusidentity.com</a>
      </div>
    </div>
  )
}

export default EndStrip;