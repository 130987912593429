import React from 'react';
import TitleSection from './TitleSection';





class SlackInstalled extends React.Component {

  constructor(props) {
    super(props);

    const slack_team_id = this.props.location.search.split('?')[1];

    // Slack app plusidentity-dev : A02AZNB3KUM
    // Slack app plusidentity : A02Q9A55M46

    // Now alert deep link back to the messages tab of the app
    let meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = this.props.mode_info.is_dev ? `0; URL=slack://app?team=${slack_team_id}&id=A02AZNB3KUM&tab=messages` : `0; URL=slack://app?team=${slack_team_id}&id=A02Q9A55M46&tab=messages`
    
    document.head.appendChild(meta);
    
    this.state = {
      
    }
  }

  async componentDidMount() {
     
  }

  render() {
    return (
      <>
        <TitleSection />
      </>
    );
  }
}

export default SlackInstalled;
