import React from 'react';
import './People.css';


import Team from './Team';
import Advisors from './Advisors';



function People() {
  return (
    <div className='about__people'>
      <Team />
      <Advisors />
    </div>
  )
}

export default People;
