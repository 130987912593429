

import { ReactComponent as FillerLogo } from './media/svg/articles__filler.svg';



export const noneYet = {
  dateText: "Today",
  Logo: FillerLogo,
  articleTitle: "No blog article or update yet",
  articleLink: "/"
}

export const zeroKnowledge = {
  dateText: "2021.12.23",
  Logo: FillerLogo,
  articleTitle: "Release of our zero-knowledge product",
  articleLink: "https://plusidentity.notion.site/Release-of-our-zero-knowledge-product-1-0-0-4c8c83945a8e46a4b77d629c4dc55ecd"
}

export const soc2 = {
  dateText: "2022.06.17",
  Logo: FillerLogo,
  articleTitle: "PlusIdentity is now SOC 2 (Type II) compliant!",
  articleLink: "https://medium.com/plusidentity/plusidentity-is-now-soc-2-type-ii-compliant-d54a71b9839f"
}