import React from 'react';
import './Team.css';

import Person from './Person';

import { Channy, Ike } from './Data';

function Team() {
  return (
    <div className='about__team'>
      <span className='about__team-title'>Our team</span>
      <div className="about__team-members">
        <Person {...Channy} />
        <Person {...Ike} />
      </div>
    </div>
  )
}

export default Team;
