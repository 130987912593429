import React from 'react';
import './Security.css';



// Data
import { Encryption, Zero, SOC2 } from './Data';

import HeroSection from '../../HeroSection';
import TitleSection from './TitleSection';
import EndStrip from './EndStrip';


// Mixpanel
import mixpanel from 'mixpanel-browser';
const MIXPANEL_PROJECT_TOKEN = 'f7ca4ed1a357be4f804b85c691051b96';
mixpanel.init(MIXPANEL_PROJECT_TOKEN); 




class Security extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount = () => {
    // Mixpanel www_security_visited
    mixpanel.track('www_security_visited', {
      is_dev: this.props.mode_info.is_dev
    });
  }

  render() {
    return (
      <>
        <TitleSection />
        <HeroSection {...Zero}/>
        <HeroSection {...Encryption}/>
        <HeroSection {...SOC2}/>
        <EndStrip />
      </>
    )
  }
  
}

export default Security;
