import React from 'react';

import { ReactComponent as One } from './svg/home__setup-section-one.svg';
import { ReactComponent as Two } from './svg/home__setup-section-two.svg';
import { ReactComponent as Three } from './svg/home__setup-section-three.svg';
import { ReactComponent as Four } from './svg/home__setup-section-four.svg';
import { ReactComponent as Five } from './svg/home__setup-section-five.svg';

const containerStyle = {
  // height: '100%',
  // width: '100%'
};

const oneStyle = {
  position: "static",

  maxWidth: 100,
  maxHeight: 100,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const twoStyle = {
  position: "static",

  // height: "50%",
  maxWidth: 100,
  maxHeight: 100,

  marginLeft: 10,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const threeStyle = {
  position: "static",
  
  maxWidth: 250,
  maxHeight: 100,

  height: '100%',
  width: '100%',

  marginTop: 10,
  marginBottom: 10,

  zIndex: 1
};

const fourStyle = {
  position: "static",
  
  maxWidth: 250,
  maxHeight: 100,

  height: '100%',
  width: '100%',

  zIndex: 1
};

const fiveStyle = {
  position: "static",

  maxHeight: 400,
  maxWidth: 400,

  height: '100%',
  width: '100%',

  zIndex: 0
};


const rowStyle = {
  display: 'flex',
  flexDirection: 'row',
  height: '50%',
  width: '100%',

  alignItems: "center",

  maxHeight: 250,
  maxWidth: 500,
}


const colStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}

const left = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  marginRight: -40,
}

const right = {
  display: 'flex',
  flex: 2
}


function SetupMedia(props) {
  return (
    <div className={props.className} style={containerStyle}>
      <div style={rowStyle}>
        <div style={left}>
          <div style={rowStyle}>
            <One className={'media-item'} style={oneStyle} />
            <Two className={'media-item'} style={twoStyle} />
          </div>
          <div style={colStyle}>
            <Three className={'media-item'} style={threeStyle} />
            <Four className={'media-item'} style={fourStyle} />
          </div>
        </div>
        <div style={right}>
          <Five className={'media-item'} style={fiveStyle} />
        </div>
      </div>
    </div>
  )
}

export default SetupMedia;