import './App.css';
import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


// NAVBAR
import Navbar from './components/Navbar';

// FOOTER
import Footer from './components/Footer';


// PAGES
// From left nav
import Home from './components/pages/HomePage/Home';

// NOT USED RIGHT NOW
// import Basic from './components/pages/Products/BasicPage/Basic';
// import Plus from './components/pages/Products/PlusPage/Plus';
// import Web from './components/pages/Products/WebPage/Web';

import Business from './components/pages/Products/BusinessPage/Business';
import Advanced from './components/pages/Products/AdvancedPage/Advanced';

import Pricing from './components/pages/PricingPage/Pricing';
import Security from './components/pages/SecurityPage/Security';



// Rest from footer
import Compliance from './components/pages/CompliancePage/Compliance';
import Articles from './components/pages/ArticlesPage/Articles';
import About from './components/pages/AboutPage/About';
import Press from './components/pages/PressPage/Press';
import Privacy from './components/pages/PrivacyPage/Privacy';
import Terms from './components/pages/TermsPage/Terms';


// Miscellaneous
import SlackInstalled from './components/pages/SlackInstalledPage/SlackInstalled';


// ScollToTop
import ScrollToTop from './components/ScrollToTop';





// Authentication functions
import { enforce_https } from './auth/util';



/*****************************************
* PRODUCTION / DEVELOPMENT TOGGLE
*****************************************/
const IS_DEV = false;
const MODE_INFO = {is_dev: IS_DEV}
/*****************************************
* END OF TOGGLE
*****************************************/














class App extends React.Component {

  constructor(props) {
    super(props);

    // Enforce HTTPS
    // * This is already enforced through network configuration and server settings (more reliable), but is enforced again here client side nonetheless for extra protection
    // TODO: figure out whether it'd be better to just not render
    if (!IS_DEV) enforce_https();

    // Upon initial contact
    // onLoad();

    this.state = {
      
    }
  }

  componentDidMount() {
    this.setState({

    });
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Navbar />
          <Switch>
            <Route 
              path='/' 
              exact 
              render={(props) => 
                <Home 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
    
            {/* <Route path='/products/basic' exact component={Basic} />
            <Route path='/products/plus' exact component={Plus} />
            <Route path='/products/web' exact component={Web} /> */}

            <Route 
              path='/products/business' 
              exact 
              render={(props) => 
                <Business 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/products/advanced' 
              exact 
              render={(props) => 
                <Advanced 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            
    
            <Route 
              path='/pricing' 
              exact 
              render={(props) => 
                <Pricing 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/security' 
              exact 
              render={(props) => 
                <Security 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />





            <Route 
              path='/slack-installed' 
              exact 
              render={(props) => 
                <SlackInstalled 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
  
    
            <Route 
              path='/compliance' 
              exact 
              render={(props) => 
                <Compliance 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/articles' 
              exact 
              render={(props) => 
                <Articles 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/about' 
              exact 
              render={(props) => 
                <About 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/press' 
              exact 
              render={(props) => 
                <Press 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/privacy' 
              exact 
              render={(props) => 
                <Privacy 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
            <Route 
              path='/terms' 
              exact 
              render={(props) => 
                <Terms 
                  {...props}
                  mode_info={MODE_INFO} 
                />
              }
            />
    
          </Switch>
          <Footer 
            mode_info={MODE_INFO} 
          />
        </ScrollToTop>
      </Router>
    );
  }
  
}

export default App;
